<template>
  <page class="daily-brief" title="Resumo Diário">
    <v-snackbar v-model="showSnackbar" top color="error">
      {{messageSnackbar}}
    </v-snackbar>
    <v-card tile color="grey lighten-3 mb-3" elevation="0">
      <v-card-text>
        <v-row class="flex-grow-0 align-end">
          <v-col cols="12" md="2">
            <v-text-field v-model="sales.startDate" type="date" label="Data inicial" />
          </v-col>
          <v-col cols="12" md="2">
            <v-text-field v-model="sales.endDate" type="date" label="Data Final" />
          </v-col>
          <v-col cols="12" class="d-flex justify-center justify-md-start">
            <v-btn :block="$vuetify.breakpoint.mobile" color="primary" @click="onClickSearch">
              <v-progress-circular v-if="isLoading" size="16" width="2" indeterminate class="mr-1" />
              <span>{{ isLoading ? 'Buscando' : 'Buscar'}}</span>
            </v-btn>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
    <v-row class="flex-grow-0">
      <v-col cols="12">
        <sales-total-general title="Total Geral" :amount="sales.salesTotal.amount" :qtde="sales.salesTotal.quant" />
      </v-col>
    </v-row>
    <v-row class="flex-grow-0">
      <v-col v-for="(item, i) in sales.salesFilialsTotal" cols="12" sm="4" :key="i">
        <sales-total :title="item.desc" :amount="item.amount" :qtde="item.qtde" />
      </v-col>
    </v-row>
  </page>
</template>

<script>
import SalesTotal from "@/components/widgets/sales-total";
import Page from "@/components/containers/page";
import mixins from "@/mixins"
import SalesTotalGeneral from "@/components/widgets/sales-total-general";
export default {
  mixins: [mixins],
  components: {SalesTotalGeneral, Page, SalesTotal},
  data: () => ({
    showSnackbar: false,
    messageSnackbar: '',
    clicked: false,
    isLoading: false
  }),
  beforeMount() {
    this.$store.commit('millennium/setStartTodayDate')
  },
  mounted() {
    this.lazyLoad()
    this.getSales();
  },
  computed: {
    sales() {
      return this.$store.state.millennium;
    }
  },
  methods: {
    getSales() {
      if(this.sales.status === 0) {
        if (this.$store.getters["auth/isAuthenticated"]) {
          this.$store.dispatch('millennium/getSales', {
            startDate: this.sales.startDate,
            endDate: this.sales.endDate
          })
              .catch((err) => {
                if (err && [401, 403].includes(err.status)) {
                  this.showSnackbar = true;
                  this.messageSnackbar = 'Sessão expirou, redirecionando para o login...';
                  setTimeout(() => {
                    this.$router.push({name: 'Login'})
                    this.$store.dispatch('auth/revokeAthentication')
                  }, 2000)
                } else {
                  console.error(err)
                }
              })
          .finally(() => {
            clearTimeout(this.__lazyLoadTimer__)
            this.isLoading = false
            this.clicked = false
          })
        }
      }
    },
    onClickSearch() {
      this.clicked = true
      this.lazyLoad()
      this.getSales()
    },
    lazyLoad() {
      this.__lazyLoadTimer__ = setTimeout(() => {
        this.isLoading = this.sales.salesStatus === 1 && this.clicked
      }, 300)
    }
  }
}
</script>
