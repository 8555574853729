export default {
  computed: {
    currency() {
      return value => this.$options.filters.currency(value, 'R$', 2, { spaceBetweenAmountAndSymbol: true, thousandsSeparator: '.', decimalSeparator: ',' })
    },
    decimal() {
      return value => !value ? 0.0 : this.$options.filters.currency(value, '', 2, { spaceBetweenAmountAndSymbol: true, thousandsSeparator: '.', decimalSeparator: ',' })
    },
    number() {
      return value => !value ? 0 : this.$options.filters.currency(value, '', 0, { spaceBetweenAmountAndSymbol: false, thousandsSeparator: '.', decimalSeparator: ',' })
    },    
  },
  methods: {
    getISODate(date) {
      return date.toISOString().split('T')[0]
    },
    catchErrors(err)  {
      if (err && [401, 403].includes(err.status)) {
        this.sessionExpire()
      } else {
        console.error(err)
        this.showSnackbar = true;
        this.messageSnackbar = 'Algo de errado ocorreu, tente novamente.';
      }
    },
    sessionExpire() {
      this.showSnackbar = true;
      this.messageSnackbar = 'Sessão expirou, redirecionando para o login...';
      setTimeout(() => {
        this.$router.push({name: 'Login'})
        this.$store.dispatch('auth/revokeAthentication')
      }, 2000)
    }
  }
}
