<template>
  <div class="sales-total fill-height">
    <v-card class="card-widget" height="100%">
      <v-layout fill-height>
        <v-card-text>
          <v-row no-gutters>
            <v-col sm="4">
              <v-row class="flex-grow-0" no-gutters>
                <v-col cols="1" class="mr-2">
                  <v-icon color="primary">mdi-store</v-icon>
                </v-col>
                <v-col cols="10" class="mb-3 subtitle-2"> {{ title }}</v-col>
                <v-col cols="12">
                  <v-divider class="mb-3 mt-2"></v-divider>
                </v-col>
                <v-col cols="1" class="mr-2">
                  <v-icon color="primary">mdi-currency-usd</v-icon>
                </v-col>
                <v-col cols="6" sm="6" md="5" class="subtitle-1">{{ currency(amount) }}</v-col>
                <v-col cols="1" class="mr-2">
                  <v-icon color="primary">mdi-cart</v-icon>
                </v-col>
                <v-col cols="3" sm="2">
                  {{ number(qtde) }}
                </v-col>
              </v-row>
            </v-col>
          </v-row>
        </v-card-text>
      </v-layout>
    </v-card>
  </div>
</template>

<script>
import mixins from '@/mixins'
export default {
  mixins: [mixins],
  props: ['title', 'amount', 'qtde']
}
</script>

<style lang="scss">

</style>
